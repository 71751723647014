// custom variants
$theme-colors: (
  "primary": #81a31b,
  "secondary": #a9c465,
  "danger": #ff6347,
  "blue": #1e254f,
  "skyBlue": #0e9cf0,
  "sky": rgb(194, 233, 255),
  "green": #98b839,
  "gray": #e0ebc0,
  "graydark": #eaeee0,
  "lightblue": rgb(133, 210, 255),
  "admingreen": #3d5654,
  "adminsecondary": #d6af14,
  "adminteritory": #18b3a7,
  "opaque": rgba(255, 255, 255, 0.328),
  "landingPrimary": #f49322,
  "landingGary": #f1f0ee,
);
// custom variables
$skyBlue: rgb(14, 156, 240);

.p-5 {
  padding: 5rem !important;
}
.py-5 {
  padding: 0rem 5rem;
}
.my-5 {
  margin: 0 5rem;
}
.p-6 {
  padding: 6rem !important;
}

.b-200 {
  font-weight: 200;
}
.b-300 {
  font-weight: 300 !important;
}
.b-400 {
  font-weight: 400 !important;
}

.b-500 {
  font-weight: 500 !important;
}

.b-600 {
  font-weight: 600 !important;
}
.b-700 {
  font-weight: 700 !important;
}
.b-800 {
  font-weight: 800 !important;
}
.b-900 {
  font-weight: 900 !important;
}
.round {
  border-radius: 1rem;
}
.round-2 {
  border-radius: 2rem;
}
.round-50 {
  border-radius: 50%;
}
.lh-0 {
  line-height: 0;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

.br-1 {
  border-radius: 1rem !important;
}
.br-2 {
  border-radius: 2rem !important;
}

.br-3 {
  border-radius: 3rem !important;
}

.br-4 {
  border-radius: 4rem !important;
}

// image
.obj-fit-cover {
  object-fit: cover;
}

.text-dn {
  text-decoration: none !important;
}

.b-o-no {
  border: none;
  outline: none;
}
.b-o-none:hover {
  border: none;
  outline: none;
}

// text size
.tiny {
  font-size: 0.9rem !important;
}

// drop shadow

.shadow {
  -webkit-box-shadow: 10px 10px 58px 13px rgba(245, 245, 245, 1);
  -moz-box-shadow: 10px 10px 58px 13px rgba(245, 245, 245, 1);
  box-shadow: 10px 10px 58px 13px rgba(245, 245, 245, 1);
}
@import "../node_modules/bootstrap/scss/bootstrap";
