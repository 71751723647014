$gray: #f1f0ee;
$primary: #f49322;

@font-face {
  font-family: "bs";
  src: url("../assets/fonts/BwSeidoRoundDEMO-Black.woff") format("woff"),
    url("../assets/fonts/BwSeidoRoundDEMO-Black.ttf") format("truetype");
  src: url("../assets/fonts/BwSeidoRoundDEMO-Bold.woff") format("woff"),
    url("../assets/fonts/BwSeidoRoundDEMO-Bold.ttf") format("truetype");
  src: url("../assets/fonts/BwSeidoRoundDEMO-BlackItalic.woff") format("woff"),
    url("../assets/fonts/BwSeidoRoundDEMO-BlackItalic.ttf") format("truetype");
  src: url("../assets/fonts/BwSeidoRoundDEMO-Light.woff") format("woff"),
    url("../assets/fonts/BwSeidoRoundDEMO-Light.ttf") format("truetype");
  src: url("../assets/fonts/BwSeidoRoundDEMO-Medium.woff") format("woff"),
    url("../assets/fonts/BwSeidoRoundDEMO-Medium.ttf") format("truetype");
  src: url("../assets/fonts/BwSeidoRoundDEMO-Regular.woff") format("woff"),
    url("../assets/fonts/BwSeidoRoundDEMO-Regular.ttf") format("truetype");
  src: url("../assets/fonts/BwSeidoRoundDEMO-Thin.woff") format("woff"),
    url("../assets/fonts/BwSeidoRoundDEMO-Thin.ttf") format("truetype");
}

* {
  // outline: 1px solid red;
  font-family: bs;
}

.search-input {
  border: 1.5px solid white;
  border-radius: 6rem;
  background: transparent;
  padding: 0.5rem 1rem;
  display: inline-block;
  margin-left: 7rem;
  outline: none;
  color: white;
  width: 17rem;
}

.search-input::placeholder {
  color: white;
}

.banner-title {
  position: absolute;
  z-index: 80;
  top: 40%;
  width: 100%;
  padding: 0rem 4rem;
  color: white;
}

@media only screen and (max-width: 768px) {
  .nav-bar-container {
    top: 0 !important;
  }
  .banner-title {
    top: 30%;
    width: 30%;
  }
  .search-container {
    display: none;
  }
}

.link-nav {
  font-size: 1.2rem !important;
  margin-right: 1em !important;
}

.coursel-img {
  height: 140vh;
  width: 100%;
  object-fit: cover;
}

#svgPath {
  height: 0;
  width: 0;
}

.banner-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  clip-path: url(#clipPath);
}

.banner-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom right,
    #f49322 20%,
    rgba(255, 255, 255, 0.632) 70%
  );
}

//shop
.shop-img {
  width: 14rem;
}

// title content

.title-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  p {
    display: block;
    width: 70%;
    font-size: 1.2rem;
    text-align: center;
  }
}

// Benfits of certification
.benefits-title {
  font-weight: 800;
  color: $primary;
}

// FAQS
.accordion-item {
  border: 2px solid $primary;
  border-left: none;
  border-right: none;
}
.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: $primary;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordin-title {
  border-left: 10px solid $primary;
  height: 3.8rem;
  padding-top: 1rem;
  padding-left: 1.2rem;
  font-size: 1.3rem;
  font-weight: 400;
}
@media only screen and (max-width: 768px) {
  .accordin-title {
    font-size: 1.1rem;
    height: 4rem;
    padding-top: 0.3rem;
  }
  .accordin-content {
    font-size: 1rem;
  }
}

// request from
.bg-image {
  background-image: linear-gradient(
      to right,
      rgba(33, 31, 31, 0.822),
      rgba(0, 0, 0, 0.368)
    ),
    url("../assets/contactbg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.input {
  border: 1px solid white;
  border-radius: 3rem;
  color: white;
  width: 100%;
  background-color: transparent;
  font-weight: 700;
  padding: 0.5em;
}

.input::placeholder {
  color: white;
}

.input-container {
  width: 50%;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .input {
    margin-bottom: 1rem;
  }
  .input-container {
    width: 85%;
  }
}
.newLetter-input {
  width: 100%;
  height: 4rem;
  border-radius: 2rem;
  background: none;
  border: 2px solid rgb(163, 160, 160);
  padding: 1rem 2rem;
  font-size: 1.6rem;
  color: rgb(163, 160, 160);
}

.newsLetter-btn {
  background: none;
  height: 4rem;
  border-radius: 4rem;
  border: 2px solid rgb(163, 160, 160);
  color: rgb(163, 160, 160);
  font-size: 1.6rem;
  width: 10rem;
}

// footer
footer {
  background: rgb(163, 160, 160);
  .link-header {
    margin: 1rem 0rem;
    font-size: 1.3rem;
    padding-bottom: 1.2rem;
  }
  .link {
    text-decoration: none;
    color: white;
    display: block;
    font-size: 1.1rem;
  }
  .link:hover {
    text-decoration: underline;
  }
}
.navlinks-container-footer {
  background-color: $primary;
  color: white;
  border-radius: 20rem 0rem 0rem 0rem;
}

@media only screen and (max-width: 768px) {
  .navlinks-container-footer {
    border-radius: 0rem;
  }
}

.cirlceVirbrate {
  animation-name: circlAnimation;
  transform-origin: 50% 50%;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: circlAnimation;
  -webkit-animation-duration: 4s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: circlAnimation;
  -moz-animation-duration: 4s;
  -moz-transform-origin: 50% 50%;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}

.cirlceVirbrate2 {
  animation-name: circlAnimation2;
  transform-origin: 50% 50%;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: circlAnimation2;
  -webkit-animation-duration: 4s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: circlAnimation2;
  -moz-animation-duration: 4s;
  -moz-transform-origin: 50% 50%;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}
.cirlceVirbrate3 {
  animation-name: circlAnimation3;
  transform-origin: 50% 50%;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: circlAnimation2;
  -webkit-animation-duration: 4s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: circlAnimation2;
  -moz-animation-duration: 4s;
  -moz-transform-origin: 50% 50%;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}

.circle {
  width: 9rem;
  height: 9rem;
  background: rgb(226, 226, 226);
  border-radius: 10rem;
  position: absolute;
  top: -70%;
  right: 40%;
  z-index: -1;
}

.circle1 {
  width: 2rem;
  height: 2rem;
  background: rgb(229, 229, 229);
  border-radius: 10rem;
  position: absolute;
  top: -70%;
  left: 0%;
}

.circle2 {
  width: 5rem;
  height: 5rem;
  background: rgb(229, 229, 229);
  border-radius: 10rem;
  position: absolute;
  top: -50%;
  right: 0%;
}

.circle3 {
  width: 4.3rem;
  height: 4.3rem;
  background: rgb(229, 229, 229);
  border-radius: 10rem;
  position: absolute;
  bottom: -30%;
  right: 40%;
}

@keyframes circlAnimation {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: scale(0.8);
  }
  70% {
    transform: translate((20px, -15px));
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes circlAnimation2 {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: scale(0.8);
  }
  70% {
    transform: translate((-0px, -20px));
  }
  70% {
    transform: translate((-15px, -20px));
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes circlAnimation3 {
  0% {
    transform: translate(0px, 0px);
  }
  30% {
    transform: scale(0.8);
  }
  50% {
    transform: translate((-4px, 11px));
  }

  70% {
    transform: translate((2px, -6px));
  }
  100% {
    transform: translate(0px, 0px);
  }
}
